import React from 'react'
import styled from 'styled-components'
import SEO from '../components/Seo'
import device from '../utils/mq'

const Wrapper = styled.div`
  max-width: 1100px;
  padding: 0 15px;
  margin: 0 auto;
  margin-bottom: 40px;
  h1 {
    font-size: 50px;
    letter-spacing: -3px;
    color: #5a55ab;
    text-align: left;
    font-weight: 700;
    @media ${device.tablet} {
      font-size: 70px;
    }
  }

  p {
    max-width: 1000px;
    margin: 0 0 1.56em;
  }
`

const WhatIsInnentialPage = () => (
  <Wrapper>
    <SEO
      title="What is Innential"
      description="Innential helps your teams perform and grow through hyper personalised learning."
    />
    <h1>What is Innential?</h1>
    <iframe
      title="what-is-innential"
      width="800"
      height="450"
      src="https://www.youtube.com/embed/c4uHZqubdbI"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Wrapper>
)

export default WhatIsInnentialPage
